@font-face {
    font-family: "Shell";
    font-style: normal;
    font-weight: 300;
    src: local("Shell Light"), url(../assets/fonts/Shell-Light.woff) format("woff");
}
@font-face {
    font-family: "Shell";
    font-style: normal;
    font-weight: 400;
    src:local('Shell'), url(../assets/fonts/Shell-Book.woff) format('woff');
}
@font-face {
    font-family: "Shell";
    font-style: normal;
    font-weight: 500;
    src: local("Shell Medium"), url(../assets/fonts/Shell-Medium.woff) format("woff");
}
@font-face {
    font-family: "Shell";
    font-style: normal;
    font-weight: 600;
    src: local("Shell Heavy"), url(../assets/fonts/Shell-Heavy.woff) format("woff");
}
@font-face {
    font-family: "Shell";
    font-style: normal;
    font-weight: 700;
    src: local("Shell Bold"), url(../assets/fonts/Shell-Bold.woff) format("woff");
}
